
import Vue from 'vue'
import { downloadFile } from './../firebase'

export default Vue.extend({
    
    methods: {
        async uploadNewFile(file: File) {
            if(file) {
                await this.$store.dispatch('projectForm/uploadFile', file)
                await this.$store.dispatch('mail/send', {
                    to: this.$store.getters['projectForm/users'],
                    subject: 'Someone add security docs for project ' + this.$store.getters['projectForm/clientName'],
                    html: '<p>Hello</p><p>Security docs as been added for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
                })
            }
        },

        async download(doc: any) {
            await downloadFile(doc.metadata.fullPath, doc.metadata.name)
        },

        onclickUpdate(id: string) {
            const e = document.getElementById(id) ?? false
            if(e) {
                e.click()
            }
        },

        async updateFile(file: File, doc: any) {
            await this.$store.dispatch('projectForm/updateFile', { oldFullPath: doc.metadata.fullPath, file })
            await this.$store.dispatch('mail/send', {
                to: this.$store.getters['projectForm/users'],
                subject: 'Someone update security docs for project ' + this.$store.getters['projectForm/clientName'],
                html: '<p>Hello</p><p>Security docs as been updated for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
            })
        },

        async remove(doc: any) {
            await this.$store.dispatch('projectForm/deleteFile', doc.metadata.fullPath)
            await this.$store.dispatch('mail/send', {
                to: this.$store.getters['projectForm/users'],
                subject: 'Someone delete security docs for project ' + this.$store.getters['projectForm/clientName'],
                html: '<p>Hello</p><p>Security docs as been deleted for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
            })
        },

        async securityDone() {
            this.$store.commit('projectForm/setSecurityStepDone', true)
			await this.$store.dispatch('projectForm/save')
            await this.$store.dispatch('mail/send', {
				to: this.$store.getters['projectForm/users'],
				subject: 'Security step is done for project ' + this.$store.getters['projectForm/clientName'],
			    html: '<p>Hello</p><p>Security step is done for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
			})
        }
    },

    computed: {
        docs() {
            return this.$store.getters['projectForm/securityDocs']
        }
    },

})
